import React, { useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import { navigate } from "gatsby";
import PostCode from '../components/general/PostCodeStep';
import { StepUrl } from '../utils/enums';
import SEOLayout from '../components/layouts/SEOLayout';
import { navigateAndSetActiveStep } from "../utils/utils";
import { GetLocalities2, GetLocalities2Variables } from "../apollo/__generated__/GetLocalities2";
import { GET_LOCALITIES } from "../apollo/queries";

const Index = (props: any) => {
  const { location } = props;

  const [
    getLocalities,
    {
      data: dataLocalities,
      loading: loadingLocalities,
      error: errorLocalities,
    },
  ] = useLazyQuery<GetLocalities2, GetLocalities2Variables>(GET_LOCALITIES);

  const queryParams = new URLSearchParams(location.search);
  const localityId = queryParams.get('locality_id');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const postcode = params.get("postcode") || '';

    if (!postcode || postcode === '') return;

    if (/^\d+$/.test(postcode) && postcode.length > 1) {
      getLocalities({
        variables: { postcode, suburb: '' },
      });
    } else {
      navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD)
    }
  }, []);

  useEffect(() => {
    if (dataLocalities?.getLocalities && dataLocalities?.getLocalities?.length > 0) {
      navigate(`/compare/?locality_id=${dataLocalities?.getLocalities[0]?.id}`)
    }
  }, [dataLocalities, loadingLocalities, errorLocalities]);


  return (
    <SEOLayout>
      <PostCode
        userType="household"
        pageUrl={StepUrl.URL_POSTCODE_HOUSEHOLD}
        localityId={localityId}
      />
    </SEOLayout>
  );
};

export default Index;
